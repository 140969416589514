import dayjs from 'dayjs';
import _isEmpty from 'lodash.isempty';
import {call, put, select, takeLatest} from 'redux-saga/effects';

import {actions as globalActions} from 'config/redux/global/actions';
import {FilterQueryRestService} from 'services/FilterQueryRestService';
import {searchPanels, searchPanelsIds} from 'services/graphql/PanelService';
import {downloadCSV} from 'utils/download';

import {convertObjToElasticQueryString} from '../../../../utils/elasticSearch';
import {debounce} from '../../../../utils/sagas';
import {actions} from './actions';
import {fetchCSVPanelDataSource} from './utils';

import type {RootState} from 'config/redux/rootReducer';
import type {SagaReturnType} from '../../../../utils/sagas';
function selectListFilterParams(state: RootState) {
  const {pagination, filters} = state.panels;

  return parseFilterToQueryParams(filters, pagination);
}

function parseFilterToQueryParams(
  filters: RootState['panels']['filters'],
  pagination: RootState['panels']['pagination'],
) {
  const {
    searchTerm,
    curationStatus,
    market: marketCode,
    dataOrigin,
    panelStatus,
    media,
    location,
    inventory: ids,
    acceptableImage: marketingImageAcceptable,
    prime,
    format,
  } = filters;

  const queryFilters = {
    ...(!_isEmpty(location) && {[location.typeKey]: location.values}),
    curationStatus,
    marketCode,
    dataOrigin,
    panelStatus,
    _id: ids,
    marketingImageAcceptable,
    media,
    prime,
    format,
  };

  const FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY = {
    'media.category': 'mediaCategory',
    'media.subCategory': 'mediaSubCategory',
    'media.names': 'mediaNameSynonyms',
  };

  const query = convertObjToElasticQueryString({
    elasticKeyDictionary: FILTER_KEY_TO_ELASTIC_QUERY_KEY_DICTIONARY,
    obj: queryFilters,
    rawQueryString: searchTerm,
  });

  return {
    limit: pagination.limit,
    skip: pagination.skip,
    sort: pagination.sort,
    query,
  };
}

function* fetchDataSaga() {
  yield put(globalActions.showLoading());

  try {
    const queryParams: ReturnType<typeof selectListFilterParams> = yield select(
      selectListFilterParams,
    );
    const {data, total}: SagaReturnType<typeof searchPanels> = yield call(
      searchPanels,
      queryParams,
    );

    yield put(actions.fetchDataSuccess({data, total}));
  } catch (err) {
    yield put(actions.fetchDataFail());
    yield put(globalActions.showMessage('💥 Unable to load surfaces'));
  } finally {
    yield put(globalActions.hideLoading());
  }
}

function* fetchCSVtoDownload() {
  const message = {
    key: 'download',
    title: 'Requesting CSV. Please wait',
    action: 'loading' as const,
  };
  try {
    yield put(globalActions.showLoading());
    yield put(globalActions.showMessageAutoHideOff(message));

    const queryParams: ReturnType<typeof selectListFilterParams> = yield select(
      selectListFilterParams,
    );

    // Get Panel ids from applied filters
    const {data}: SagaReturnType<typeof searchPanelsIds> = yield call(searchPanelsIds, {
      ...queryParams,
      skip: 0,
      limit: 10000,
    });

    // Request csv from query-api creating an inventory filter.
    const csvResponse = yield call(() =>
      FilterQueryRestService.getInstance().queryCSV(
        data.map((p) => p.id),
        fetchCSVPanelDataSource,
      ),
    );

    // get first line(header) and replace the "mad." prefix.
    const parsedCsvResponse = csvResponse.replace(/^.*/, (m) => {
      return m.replace(/mad\./g, '');
    });

    const now = dayjs().format('YYYYMMDDHHmmss');
    yield downloadCSV(`mad-inventory-grid-${now}`, parsedCsvResponse);
  } catch (e) {
    yield put(globalActions.showMessage('Unable to generate report.'));
    console.error(e);
  } finally {
    yield put(globalActions.hideMessage(message));
    yield put(globalActions.hideLoading());
  }
}

export const panelsPageSaga = function* () {
  yield debounce(
    500,
    [
      actions.fetchData,
      actions.setPageSort,
      actions.setPageLimit,
      actions.setPageSkip,
      actions.setFilters,
      actions.resetFilters,
    ],
    fetchDataSaga,
  );
  yield takeLatest(actions.downloadCSV, fetchCSVtoDownload);
};

import React, {Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {authorization} from '@onsmart/auth-client';

import Layout from 'components/common/Layout/Layout';
import Activities from 'components/pages/Activities';
import AssetForm from 'components/pages/AssetForm';
import Inventory from 'components/pages/Inventory';
import Keywords from 'components/pages/Keywords';
import Markets from 'components/pages/Markets';
import PanelForm from 'components/pages/PanelForm';
import Panels from 'components/pages/Panels';
import QuintileDataSets from 'components/pages/QuintileDataSets';
import Showings from 'components/pages/Showings';

import type {FunctionComponent} from 'react';
const {Ready} = authorization;

export enum RouteEnum {
  markets = '/markets',
  inventory = '/inventory',
  inventoryForm = '/inventory/:id',
  keywords = '/keywords',
  keywordsCategory = '/keywords/:category',
  keywordsCategoryForm = '/keywords/:category/:keyword',
  panels = '/panels',
  panelsNew = '/panels/new',
  panelsForm = '/panels/:id',
  activity = '/activity',
  showings = '/showings',
  quintileDataSets = '/quintiles',
}

const withRouteProps =
  <T extends unknown>(WrappedComponent: React.ComponentType<T>) =>
  (props: T) =>
    <Ready render={() => <WrappedComponent {...props} />} />;

const ReadyAssetForm = withRouteProps(AssetForm);
const ReadyKeywords = withRouteProps(Keywords);
const ReadyPanelForm = withRouteProps(PanelForm);

export const App: FunctionComponent = (props) => (
  <Layout>
    <Suspense fallback={<div />}>
      <Switch>
        <Route path={RouteEnum.showings} render={() => <Ready render={() => <Showings />} />} />
        <Route
          path={RouteEnum.quintileDataSets}
          render={() => <Ready render={() => <QuintileDataSets />} />}
        />
        <Route path={RouteEnum.markets} render={() => <Ready render={() => <Markets />} />} />
        <Route exact path={RouteEnum.panels} render={() => <Ready render={() => <Panels />} />} />
        <Route
          exact
          path={RouteEnum.inventory}
          render={() => <Ready render={() => <Inventory />} />}
        />
        <Route path={RouteEnum.inventoryForm} component={ReadyAssetForm} />
        <Route exact path={RouteEnum.keywords} component={ReadyKeywords} />
        <Route exact path={RouteEnum.keywordsCategory} component={ReadyKeywords} />
        <Route exact path={RouteEnum.keywordsCategoryForm} component={ReadyKeywords} />
        <Route path={RouteEnum.panelsForm} component={ReadyPanelForm} />
        <Route
          path={RouteEnum.activity}
          render={(props) => <Ready render={() => <Activities {...props} />} />}
        />
        <Redirect to={RouteEnum.markets} />
      </Switch>
    </Suspense>
  </Layout>
);

App.displayName = 'App';
